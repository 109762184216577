.toggleBar {
    width: 100%;

}
.bgColor {
    background-color: #F3F3F3;
}

@media (max-width: 1024px) {
    .toggleBar {
        width: 444px;
    }
}

@media (max-width: 768px) {
    .toggleBar {
        width: 376px;
    }
}

@media (max-width: 480px) {
    .toggleBar {
        width: 100%;
    }
}