.header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Proxima Nova Lt';
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.49px;
    // position: relative;
    
    .linkLogo {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #000000;

        .logoName {
            margin-left: 16px;
        }
    }

    .nav {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #FFFFFF;

        .contentNav {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 42px;
            background-color: #FFFFFF;

            .mobileToggleMenu {
                display: none;
            }
        }

        .popup {
            width: 100%;
            z-index: 2000;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 48px;
            background-color: #FFFFFF;
        }

        .fadeInAnimation {
            animation: fadeIn .25s;

        }

    }
}

@media (max-width: 1024px) {
    .header {
        .nav {
            .contentNav {
                padding: 0 24px;
            }

            .popup {
                padding-right: 24px
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
      

        .nav {
            .contentNav {
            z-index: 2100;

                .mobileToggleMenu {
                    display: block;
                }
            }

            .popup {
                overflow: auto;
                height: 100%;
                position: fixed;
                inset: 82px 0 0 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                padding: 0;
            }
        }
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}