.fullWidth {
    width: 99%;
    margin: 24px 2px;
}

.scroll {
    width: 95%;
    margin: 24px 2px 24px 2px;
}

.card {
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px #87878740;

    .cardHeader {
        padding: 18px 24px;
        border-bottom: 1px solid #E1E1E1E1;
   

        .infoHeader {
            display: flex;
            align-items: center;
            justify-content:space-between;
        }
        
        .numberStatus {
            display: flex;
            align-items: center;
        }

        .order {
            font-size: 17px;
            font-weight: 700;
            line-height: 20.71px;
        }

        .status {
            padding: 6px 8px;
            border-radius: 5px;
            width: max-content;
            margin-left: 24px;

            .statusName {
                font-size: 14px;
                font-weight: 700;
                line-height: 17.05px;
                color: #FFFFFF;
            }
        }

        .cancelDesc {
            // font-size: 13px;
            // font-weight: 400;
            // line-height: 15.83px;
            font-size: 15px;
            font-weight: 400;
            line-height: 18.27px;
            text-align: left;
            padding-top: 12px;
            max-width: 100%;
            color:#ED0000;
        }

        .progress {
            background-color: #006FED;
        }

        .successed {
            background-color: #45C342;
        }

        .cancelled {
            background-color: #ED0000;
        }

       
    }


    .cardBody {
        padding: 24px 24px 0 24px;
        display: flex;
        align-items: center;

        .amountCurrency {
            font-size: 20px;
            font-weight: 700;
            line-height: 24.36px;

        }

        .arrow {
            margin: 0 48px
        }

        .divider {
            display: none;
        }
    }

    .formatDate {
        font-size: 15px;
        font-weight: 400;
        line-height: 18.27px;
        // text-align: right;
        color: #878787;

        .br {
            display: none;
        }

        .slash {
            display: inline;
        }
        .country {
            color: #878787;
        }
    }


    .cardFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        .link {
            text-decoration: none;

        }
        .linkName {
            height: max-content;
            font-size: 14px;
            font-weight: 700;
            line-height: 15.4px;
            color:#006FED;
        }
    }
}

.card:nth-of-type(1) {
    margin: 2px;
}

.fullWidth:nth-of-type(1) {
    margin-left: 1px;
}

@media (max-width: 768px) {
    .card {
        margin: 12px 0 12px 2px;
        .cardHeader {
            padding: 16px;
            border-bottom: 0;

            .order {
                font-size: 14px;
                font-weight: 700;
                line-height: 17.05px;
            }
    
            .infoHeader {
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;
            }

            .numberStatus {
                width: 100%;
                justify-content: space-between;
            }

            .status {
                padding: 2px 8px 2px 8px;
                font-size: 13px;
                font-weight: 700;
                line-height: 15.83px;
                border-radius: 10px;
            }
        }

        .cardBody {
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .cardBodyContent {
                padding: 12px 16px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
            }
            .cardBodyContent:nth-of-type(1) {
                padding: 0 16px 12px 16px;
            }

            .arrow {
                display: none;
            }

            .divider {
                display: block;
                width: 100%;
                height: 2px;
            }


            .amountCurrency {
                font-size: 16px;
                font-weight: 400;
                line-height: 19.49px;
            }
        }

        .formatDate {
            color: #000000;


            .br {
                display: block;
            }

            .slash {
                display: none;
            }
            .country {
                color: #C2C2C2;
            }
        }
        .cardFooter {
            padding: 0 16px 16px 16px;
        }
    }
}

// @media (max-width: 480px) {

//     .card {
//         .cardBody {
//             .cardBodyContent {
//                 width: 100%;
//             }
//         }
//     }
// }