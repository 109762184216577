.content {
    width: 100%;
    padding: 48px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    .link {
        width: 100%;
        text-align: center;

        .buttonLinkMain {
            width: 333px;
            height: 67px;
        }
    }    
    .subTitleNoHistory {
        color: #808080;
        text-align: center;
        margin: 24px 0;
    }
    
    .titleNoHistory {
        font-size: 25px;
        font-weight: 700;
        line-height: 27.5px;
        text-align: center;
    }
    
}

@media (max-width: 480px) {
    .content {
        padding: 12px;
        height: auto;

        .link {
            .buttonLinkMain {
                width: 100%;
            }
        }
        
    }
}