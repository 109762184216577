.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.currency {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 19.01px;
  font-weight: 700;
  line-height: 23.15px;
  color: #000000;
}

/* html, body {
  width: 100%;
  height: 100%;
} */

#root {
  width: 100vw;
  height: 100vh;
}

:root {
  --main-size-xl: 1440px;
  --main-size-lg: 1024px;
  --main-size-md: 768px;
  --main-size-sm: 425px;
}


/* nav-burger */

.nav-toggle {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  width: 22px;
}

.nav-toggle .bar {
  height: 2px;
  width: 100%;
  background-color: black;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  border-radius: 2px;
}
.bar:nth-of-type(3) {
  height: 2px;
  width: 60%;
  border-radius: 2px;
}

.x:nth-of-type(1) {
  transition: all 200ms ease-in-out;
  transform: rotate(0.13turn);;
  transform-origin: top left;
  width: 28px;
}

.x:nth-of-type(2) {
  transition: all 200ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 200ms ease-in-out;
  transform: rotate(-0.13turn);
  transform-origin: bottom left;
  width: 28px;
}

/* customPopup */

.toggle-line {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
  width: 22px;
}

.toggle-line .line {
  height: 2px;
  width: 100%;
  background-color: black;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  border-radius: 2px;
}
.line:nth-of-type(3) {
  height: 2px;
  width: 60%;
  border-radius: 2px;
}

.cross:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(0.13turn);;
  transform-origin: top left;
  width: 22px;
}

.cross:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.cross:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-0.13turn);
  transform-origin: bottom left;
  width: 22px;
}