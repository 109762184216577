.circleWrap {
    width: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    .circle {
        border-radius: 50%;
        background-color: #ECECEC;
        width: 74px;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;

        .nameCircle {
            font-size: 18px;
            font-weight: 700;
            line-height: 19.8px;
            text-align: center;
        }

       
    }
    .descriptionCircle {
        color:#808080;
        margin-top: 16px;
        font-size: 13px;
        font-weight: 400;
        line-height: 14.3px;
        text-align: center;

    }
}


@media (max-width: 1024px) {
    .circleWrap {
        flex-direction: row;
        justify-content: space-between;
        width: calc(74px + 128px + 24px);
        margin: 24px 0;
    
        .descriptionCircle {
            display: inline-block;
            width: 128px;
            margin-top: 0;
        }
    }

}