.link {
    text-decoration: none;
}

.baseCard {
    width: 494px;
    height: 201px;
    padding: 36px 36px 18px 36px;
    border-radius: 10px;
    text-transform: uppercase;

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.23px;
        text-align: left;
        cursor: inherit;
    }

    .textBlack {
        color: #000000;
    }


    .cardContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .subTitle {
        font-size: 15px;
        font-weight: 700;
        line-height: 18.27px;
        text-align: left;
        cursor: inherit;
    }
    

    .grayText {
        color: #B8B8B8;
    }
    
}


.grayCard {
    background: linear-gradient(110.53deg, #F0F0F0 2.4%, #E3E3E3 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}


@media (max-width: 768px) {
    .baseCard {
        width: 100%;
    }
}