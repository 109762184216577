.text {
    font-weight: 400 !important;
    font-size: 16px !important; 
    line-height: 19.49px !important;
    color: #FFFFFF !important;
}

@media (max-width: 768px) {
    .text {
        color: #000000 !important;
        padding-bottom: 6px;
    }
}