.panel {
    width: 444px;
    height: 176px;
    border-radius: 12px;
    margin-top: 24px;
    background-color: #FFFFFF;
    // border: 2px solid transparent;
    position: relative;
    box-shadow: 0px 0px 5.94px 0px #87878740;
    
    .focusMoneyDescription {
        background-color: #006FED;
        border-radius: 0 0 12px 12px;
        border: 2px solid #006FED;
        position: absolute;
        z-index: 190;
        width: 100%;
        top: 82px;
        display: flex;
        padding: 10px 12px 8px;
        align-items: center;
        justify-content: flex-start;
    }

    .focusMoneyInput {
        // z-index: 250 !important;
        border: 2px solid #006FED !important;
    }

    .borderTopPanel {
        border: 2px solid transparent;
        // z-index: 200;
    }
    .borderBottomPanel {
        border: 2px solid transparent;
        z-index: 180;
    }

    .panelErrorsTop {
        border: 2px solid #ED0000;
        z-index: 355;
    }
    
    .panelInputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 12px 12px 12px;
        position: relative;
        border-radius: 8px;
        background-color: #FFFFFF;
    }

    .borderTopPanel:hover {
        border: 2px solid #006FED;
    }
    .borderBottomPanel:hover {
        border: 2px solid #006FED;
    }

    .errors {
        padding: 8px 12px 2px;
        border-radius: 0 0 12px 12px;
        border: 2px solid #ED0000;
        background-color: #ED0000;
        text-align: center;
        color:#FFFFFF;
        position: absolute;
        width: 448px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.49px;
        z-index: 170;
        top: 170px;
        left: -2px;
    }



    
    .panelErrorsBottom {
        border: 2px solid #ED0000;
        z-index: 180;
    }

    

    .hiddenElement {
        height: 76px;
    }
}

.clearBorder {
    outline: 0px !important;
}

.borderError {
    outline: 2px solid #ED0000;
    border-radius: 12px 12px 0 0;
}
.borderError:hover {
    outline: 2px solid transparent;
    
    .errors {
        display: none;
    }
}

.selects {
    min-width: 55%;
}

.selectType {
    display: flex;
    margin-bottom: 4px;
}

.wrapSelect {
    margin-left: 12px;
}

.inputSum {
    width: 159px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addBorder {
    border-bottom: 1px solid #EFEFEF;
}
