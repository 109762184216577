.selectMui {
    width: 100%;
    height: 19px;
    padding: 0 2px 0 2px;
    // font-family: 'Proxima Nova Lt';
    // font-weight: 400;
    // font-size: 16px;
    // line-height: '19px';
    background-color: #006FED;
    border: none;
    text-transform: none;
}