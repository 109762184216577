.styleInput {
    input[type=text]::-webkit-inner-spin-button,
    input[type=text]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    
    input[type="text"] {
      -moz-appearance: textfield;
    }
}

.progress {
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
}