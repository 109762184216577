.container {
    padding-bottom: 16vh;
    .subtitle {
        margin-top: 24px;
    }

    .nameService {
        font-weight: 800;
    }

    .description {
        margin-top: 14px;
    }

}