.content {
    padding: 48px;
}

.changeCard {
    background-color: #FAFAFA;
}


@media (max-width: 1024px){

    .content {
        padding: 0 36px;
    }

    .changeCard {
        background-color: transparent;
    }
}
