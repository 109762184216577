.container {

    .weight {
        font-weight: 700;
    }

    .subtitle {
        margin-top: 24px;
    }

    .description {
        margin-top: 14px;
    }

    .liContent {
        margin: 14px 0;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 19.8px;
    }

    ol { 
        counter-reset: item;
    }

    li { 
        display: block; 
        font-size: 16px;
        font-weight: 400;
        line-height: 17.6px;
        margin: 14px 0;
    }

    li::before {
        font-weight: 600;
    }

    li:before { content: counters(item, ".") ". "; counter-increment: item }
}

@media (max-width: 1024px) {
    .container {

        .title {
            font-size: 16px;
            line-height: 17.6px;
        }

        ol {
            padding-left: 24px;
        }
    }
}


@media (max-width: 768px) {
    .container {
        ol {
            padding-left: 12px;
        }
    }
}
