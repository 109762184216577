.telegramWidget {
    background-color: #CACACAB2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    
    .cardContent {
        width: 592px;
        border-radius: 20px;
        background-color: #FFFFFF;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .cardTitle {
            margin-top: 40px;
        }

        .cardDescription {
            margin-top: 18px;
        }


        .buttons {
            margin-top: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            
            .buttonBack {
                margin-top: 18px;
                height: 40px;
            }
        }

        .qrAuth {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .qrDescription {
                margin-top: 6px;
            }
        }
    }
}

.qrCode {
    height: auto;
    margin: 24px;
    max-width: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
}

.description {
    max-width: 200px;
    margin: 0 0 0 24px;
}
.spanLinkBot {
    cursor: pointer;
    color: #006FED;
}

.buttonBackAuth {
    margin-top: 18px;
    height: 40px;
}

@media (max-width: 768px) {
    .qrCode {
        min-width: -webkit-fill-available;
        width: 100%;
        margin: 12px 0;
    }
}