


.autocompleteWrapper {
    position: fixed;
    height: 100vh;
    z-index: 8100;
    overflow: hidden;
}

.option {
    inset: 6vh 0 0 0;
}
.noOption {
    inset: 0;
}

    .gropeToggleType {
        margin-top: 24px;
        padding: 0 24px 0 12px;
    }