.listWrapper {

    .marginFullScreen {
        margin-right: 0;
    }

    .buttonList {
        width: fit-content;
        height: 36px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
        text-align: left;
        background-color: #F5F5F5;
        color:#000000;
        border-radius: 10px;
    }

    .buttonList:hover {
        background-color: #cdcdcd;
    }

    .active {
        background-color: #006FED;
        color: #FFFFFF;
    }

    .active:hover {
        background-color: #2288fc;
    }
}

.fullScreen {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .listWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #FFFFFF;


        .buttonList {
            margin-right: 0;
            justify-content: flex-start;
            width: 100%;
            border-radius: 0;
            border-bottom: 1px solid #E4E4E4;
            padding: 14px;
            height: auto;
            z-index: 1;
        }

        .active {
            background-color: #006FED;
            color: #FFFFFF;
        }

        .buttonList:first-child {
            border-radius: 6px 6px 0 0;
        }
        .buttonList:last-child {
            border-radius: 0 0 6px 6px ;
        }
    }
}