.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34.1px;
    text-align: left;
}


.wrapPanelSelect {
    position: relative;
    display: none;
}

.hiddenWeb {
    display: none;
}
.container {
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    .categories {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .category {
            justify-content: flex-start;
            width: 182px;
            font-size: 17px;
            font-weight: 700;
            text-align: start;
            color: #000000;
            padding: 0;
            margin-top: 24px;
        }
    
        .active {
            color: #006FED;
        }
    }

    .categories > .category:first-child {
        margin-top: 0;
    }

    .hiddenMobile {
        display: block;
    }
}

.blur {
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 25% 0 0 0;
    background-color: #FFFFFF87;
    backdrop-filter: blur(4.5px);
}
@media (max-width: 1024px) {
    .container {
        flex-direction: column;

        .categories {
            flex-direction: row;
            align-items: flex-start;
            margin: 24px 0;

            .category {
                width: max-content;
                padding: 8px 12px;
                height: max-content;
                margin-top: 0;
                margin-right: 24px;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.49px;
                background-color: #E9E9E9;
                color: #848484;
                text-align: center;
            }

            .active {
                color: #FFFFFF;
                background-color: #006FED;
            }
        }

       
    }
    

}


@media (max-width: 768px) {
    .wrapPanelSelect {
        margin-top: 24px;
        display: block;
    }

        .container {
            margin: 0;
            width: 100%;
            background-color: #FFFFFF;

                .categories {
                    flex-direction: column;
                    width: 100%;
                    margin: 0;

                    .category {
                        width: 100%;
                        height: auto;
                        padding: 14px;
                        margin: 0;
                    }

                    .active {
                        color: #FFFFFF;
                        background-color: #006FED;
                    }
                }
        }
    .hiddenMobile {
        display: none;
    }

 
}
