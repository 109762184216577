.content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loading {
    align-items: center;
}

@media (max-width: 768px) {
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9000;
        background-color: #FFFFFF;
    }
}