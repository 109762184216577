.layout {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-areas:
    "head"
    "main"
    "footer";
    grid-template-columns: 12fr;
    grid-template-rows: 1fr 9fr 2fr;
    // overflow-x: hidden;
    overflow-y: auto;
    position: relative;
}

.head {
    grid-area: head;
    min-width: var(--main-size-lg);
    height: 10vh;
    justify-self: center;
    // padding: 0 42px;
}

.main {
    grid-area: main;
    width: 100%;
    max-width: var(--main-size-lg);
    justify-self: center;

}

.footer {
    grid-area: footer;
    background-color: #F8F8F8ED;
    width: 100%;
    margin-top: 36px;
}




@media (max-width: 1024px) {

    .head {
        min-width: var(--main-size-md);
    }
    
    .main {
        justify-self: auto;
    }

}

@media (max-width: 768px) {
    .layout {
        grid-template-areas:
        "head"
        "main";
        grid-template-rows: 1fr 11fr;
    }

    .head {
        min-width: 100%;
        height: 82px;
        position: fixed;
        top: 0;
        background: #FFF;
        z-index: 8000;
    }

    .main {
        overflow: auto;
        padding-top: 18px;
    }
    
    .footer {
        display: none;
    }

}