
.popup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: 100%;
    height: 48px;
    padding: 14px 24px 14px 14px;
    border-radius: 10px;
    background-color: #F5F5F5;
    
    .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
    }

    .toggleButton {
        align-items: center;
        justify-content: center;
        width: 22px;
        padding: 0;
        margin: 0;
    }
}

.wrapPanel {
    position: absolute;
    width: 100%;
    top: 56px;
    z-index: 100;

}