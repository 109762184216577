.active {
    color: #006FED !important;
}

.navBarList {
    width: max-content;

    .link {
        width: max-content;
        height: max-content;
        margin: 0 24px;
        color: #000000;
        font-family: 'Proxima Nova Rg';
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
    }

    .link:nth-of-type(1) {
        display: none;
    }
}

.profile {
    position: relative;
    margin-left: 140px;

    .tgVisible {
        display: flex;
        margin: 0 36px;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.92px;
        width: 100%;
        max-width: 334px;
        height: 67px;
        color: #FFFFFF;
    }

    .tgHide {
        display: none;
    }


   

    .navUser {
        color:#006FED;
    }

    
    .userNameProfile {
        display: flex;
        align-items: center;
    }

    .profileList {
        display: none;
        align-items: flex-end;
        padding: 8px;
        flex-direction: column;
        justify-content: space-between;
        background-color: #FFFFFF;
        width: 212px;
        position: absolute;
        top: 0;
        right: -2px;
        border-radius: 12px;
        border: 1px;
        box-shadow: 0px 0px 5.94px 0px #87878740;



        .user {
            display: flex;
            align-items: center;
            padding: 8px 4px;
        }


        .history {
            color:#000000;
        }

        .logout {
            color:#A19F9C;
        }
    }
    

    .buttonOpen {
        width:  max-content;
        height: max-content;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
    }
}

.profile:hover .profileList {
    display: flex;
}

@media (max-width: 1024px) {
    .profile {
        margin-left: 74px;
    }
}

@media (max-width: 768px) {
    .navBarList {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .link {
            margin: 12px;
            font-size: 24px;
            font-weight: 700;
            line-height: 29.23px;
            text-align: center;
            color: #000000;
        }
        .link:nth-of-type(1) {
            display: block;
        }
    }

    .profile {
        margin-left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 24px;
        min-width: 334px;

        .tgVisible {
            margin: 0;
            max-width: none;
        }

        .userNameProfile {
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
        }

        .profileList {
            display: flex;
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
            align-items: center;
            padding: 36px 0;
            background-color: #F5F5F5;
            box-shadow: none;
           

            .userName {
                font-size: 24px;
                font-weight: 700;
                line-height: 29.23px;
                text-align: center;
            }

           
            .user {
                padding: 0;
                margin: 12px 0;
            }
    
        }

        .buttonLink {
            font-size: 24px;
            font-weight: 700;
            line-height: 29.23px;
            text-align: center;
            height: max-content;
            width: max-content;
            margin: 12px 0;
            color: #000000;
        }

        .buttonOpen {
            margin: 24px 36px;
            font-size: 18px;
            font-weight: 700;
            line-height: 21.92px;
            text-align: center;
            width: 100%;
            max-width: 334px;
            height: 67px;
            color: #FFFFFF;
            background-color: #006FED;
        }

        .navUser {
            color:#006FED;
        }
    
    }
}


@media (max-width: 480px) {
    .profile {
        width: 100%;

        .buttonOpen {
            margin: 24px 0;
            max-width: none;
        }
    }
}