.wrap {
    width: 1024px;
    display: flex;
    justify-content: center;
    
    .container {
        max-width: 308px;
        margin-top: 64px;

        .notFoundNumber {
            font-size: 170px;
            font-weight: 700;
            color: #F0F0F0;
        }
        .notFoundNumber::after {
                content: '🥺';
                display: flex;
                align-items: center;
                font-size: 130px;
                position: relative;
                left: 62px;
                width: 130px;
                height: 130px;
        }

        .textContent {
            margin-top: 36px;
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;

            .subtitle {
                margin-top: 18px;
                margin-bottom: 60px;
            }
        }

    
    }
}

@media (max-width: 1024px) {
    .wrap {
        width: 100%;
        padding-bottom: 16vh;

    }
}