.wrapForm {
    width: 100%;
    margin: 12px 0 0 0;
    display: flex;
    align-items: center;


    .controllerWrap {
        width: 100%;
        box-shadow: 0px 0px 5px 0px #87878740;
        border-radius: 10px;
        margin: 12px;
        padding: 18px 12px 4px 12px;
    }

    .headerAutocompleteMobile {
        display: flex;
        align-items: center;
        justify-content: center;

        .autocompleteInputWrap {
            width: 100%;
            box-shadow: 0px 0px 5px 0px #87878740;
            border-radius: 8px;
            padding: 16px 24px;
        }

        .autocompleteInput {
            color: #000000;
            padding: 0;
            width: 100%;
           
        }

     

        .label {
            font-size: 13px;
            font-weight: 400;
            line-height: 15.83px;
            color: #C2C2C2;
        }
    }

    .close {
        margin: 0 24px 0 12px;
        width: 28px;
        height: 28px;
        color:#000000;
        padding: 0;
    }
}

