

.historyWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16vh;

}
.container {
   display: flex;
    flex-direction: column;
}

.contentHistory {
    --trust-pilot-card-width: 360px;
    display: flex;
    margin-top: 24px;
    flex-direction: row;
}

.historyItemsContent {
    width: calc(var(--main-size-lg) - 36px - var(--trust-pilot-card-width));
    margin-right: 36px;

    .arrayTransfer {
        overflow-y: auto;
        height: 550px;
        margin-top: 24px;
    }
}

.trustPilotWrapper {
    width: var(--trust-pilot-card-width);
    height: 100%;

    .askRequest {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.83px;
        text-align: left;
        color: #C2C2C2;
    }
    
    .trustPilot {
        width: 100%;
        height: 204px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(110.53deg, #E2F597 2.4%, #CCED00 100%);
    }
}


@media (max-width: 1024px){

    .container {
        // width: var(--main-size-md);
        align-items: flex-start;
    }

    .contentHistory {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .historyItemsContent {
        margin-right: 0;

        .arrayTransfer {
            overflow-y: auto;
            height: 500px;
           
        }

        .heightList {
            height: auto;
        }
    }

    .trustPilotWrapper {
        width: 70%;
        padding: 24px 0;
    }

}

@media (max-width: 768px) {
    .container {
        padding: 24px;
        width: 100%;
    }

    .historyItemsContent {
        margin-right: 0;
        width: 100%;
        
        
    }

    .showTrustPilot {
        display: block;
    }
    .hidden {
        display: none;
    }
    .trustPilotWrapper {
        padding: 24px 0 0 0;
    }
    
}





@media (max-width: 480px) {
    .container {
        padding: 12px;
    }

    .contentHistory {
        width: 100%;
    }

    .trustPilotWrapper {
        width: 100%;
    }
}



.arrayTransfer::-webkit-scrollbar {
    width: 8px;
    right: 2px;
  }
  .arrayTransfer::-webkit-scrollbar-thumb {
    width: 6px;
    height: 30px;
    background-color:#A1A2A3;
    border-radius: 11px;
  }
  
  .arrayTransfer::-webkit-scrollbar-track {
    background-color: #F0F0F0;
    /* background: transparent; */
    width: 8px;
    height: 10px;
  }
