.wrapPopup {
    background-color: #FFFFFF;

    .disabled {
        cursor: default;
        color: rgba(0, 0, 0, 0.47);
    }
    .unDisabled {
        cursor: pointer;
        color: #000000;
    }

    .showSearch {
        height: 23px;
        font-size: 19px;
        font-weight: 400;
        line-height: 21px;
        text-transform: none;
    }

    .hideSearch {
        width: 24px;
        height: 24px;
    }
    .showSearch:hover {
        background-color: inherit;
    }

    .popup {
        position: absolute;
        top: -2px;
        left: -2px;
        width: 444px;
        background-color: #ffffff;
        padding: 24px 0;
        display: flex;
        z-index: 400;
        border-radius: 8px;
        border: 2px solid #006FED;
    }

    .bgTransparent {
        background-color: #ffffff69;
    }


    .hiddenInput {
        visibility: hidden;
    }
    
    .currency {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 19.01px;
        font-weight: 700;
        line-height: 23.15px;
        color: #000000;
        padding: 0 24px;
        background-color: red;
    }
}




  