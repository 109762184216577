.footerWrap {
    height: 382px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .containerFooter {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        width: var(--main-size-lg);
        height: 292px;
        padding: 0;

        .links {
            width: 648px;

            .containerLinks {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
    
    
                .blockLinks {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    height: 200px;
                    
                    .title {
                        margin-bottom: 12px;
                        color: #060606;
                    }
                    
                    .social {
                        margin-top: 24px;
                    }

                    .link {
                        text-decoration: none;
                        cursor: pointer;
                        margin: 6px 0;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.49px;
                        color: #4E4E4E;
                    }

                    .logoFooter {
                        margin-top: 24px;
                    }
                }

            }

            .description {
                margin-top: 36px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.49px;
                color: #8B8B8B;

                .span {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19.49px;
                }
            }

        }

        .trustPilot {
            width: calc(var(--main-size-lg) - 648px);
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0;

            .cardLink {
                text-decoration: none;
            }

            .trustPilotCard {
                width: 190px;
                height: 116px;
                border-radius: 10px;
                padding-left: 32px;
                background-color: #EDEDED;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .cardTitle {
                    font-size: 16.99px;
                    font-weight: 700;
                    line-height: 20.7px;
                    text-align: left;
                    cursor: inherit;
                }
                
                .stars {
                    width: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 12px;
                }

            }
        }

        
    }
}

@media (max-width: 1024px) {
    .footerWrap {
        height: auto;

        .containerFooter {
            flex-direction: column;
            height: auto;
            width: auto;
            padding: 24px 0;


            .trustPilot {
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-top: 12px;
            }
        }
    }
}