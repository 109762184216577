.divider {
    width: 100%;
    position: relative;
    z-index: 190;

    .swapButton {
        color:#B7B7B7;
        position: absolute;
        right: 22px;
        background-color: #F3F3F3;
        border-radius: 24px;
        top: -20px;
        width: 96px;
        height: 40px; 

        font-size: 15.44px;
        font-weight: 700;
        line-height: 18.81px;

    }

    .swapButton:hover {
        background-color: #006FED;
        color: #FFFFFF;
    }
}