.mobilePanel {
    width: 376px;
    height: auto;
    margin-top: 24px;
    box-shadow: 0px 0px 5px 0px #87878740;
    border-radius: 10px;
    position: relative;
    
    .inputs {
        width: 100%;
        height: auto;
        padding: 16px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .inputsContainer {
            display: flex;
            align-items: center;
            height: 100%;
        }

        .typeTransfer {
            width: max-content;
            padding: 0;
            height: 16px;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.83px;
            color: #C2C2C2;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: none;
            min-width: auto;

            .spanType {
                margin-left: 4px;
                padding: 2px 12px;
                background-color: #006FED;
                color: #FFFFFF;
                height: 16px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .defaultStylesButton {
            height: 100%;
            min-width: auto;
            width: max-content;
            height: max-content;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.49px;
            color: #000000;
            text-transform: none;
            position: relative;
        }

        .city {
            width: 164px;
            display: flex;
            justify-content: flex-start;
            margin-top: 8px;
            flex-direction: column;
            align-items: flex-start;
        }

        .cityName {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.49px;
            color: #000000;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: clip;
        }

        .sum {
            width: 100%;
            height: 100%;
        }

        .sumName {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.49px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: clip;
            text-align: right;
            width: 100%;
        }

        .sumNameTo {
            color: rgba(0, 0, 0, 0.38);
        }
        
        
        .currency {
            margin-left: 12px;
            width: 41px;
            display: flex;
            justify-content: flex-end;
        }


        .hidden {
            display: none !important;
        }
    }

    .slide {
        position: fixed;
        height: 100vh;
        inset: 0;
        z-index: 8100;
        overflow: hidden;
    }
 

    .errors {
        padding-left: 12px;
    padding-top: 24px;
    padding-right: 12px;
    color: #ED0000;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.83px;
    text-align: left;
    text-transform: none;
    background-color: #FFFFFF;
    z-index: 5000;
    position: relative;
    min-height: 68px;
    top: -6px;

    }

  
}

.error {
    border:2px solid#ED0000 !important;
    border-radius: 10px;
}

@media (max-width: 480px) {
    .mobilePanel {
        width: 100%;
        min-width: 335px;



        .inputs {
            padding: 12px 16px;

            
            .city {
                max-width: 164px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }    
    }   
}
@media (max-width: 400px) {
    .mobilePanel {
        min-width: auto;

     
    }   
}