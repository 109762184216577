.pageError {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .contentError {
        max-width: 333px;
        margin-top: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;


        .buttonReload {
            margin-top: 18px;
        }
       
    }
}

@media (max-width: 768px) {
    .pageError {
        margin-top: 10vh;
    }
}