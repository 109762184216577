.contentFaq {
    margin-left: 24px;

    .accordionStyle {
        background-color: #FFFFFF;
    }
}
@media (max-width: 1024px) {
    .contentFaq {
        margin-left: 0;

        .accordionStyle {
            background-color: #F5F5F5;
            padding-right: 0;
        }
    }
}

@media (max-width: 768px) {
    .contentFaq {
        margin-top: 24px;
    }
}