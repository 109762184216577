.approveWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16vh;

    .transferTag {
        display: block;
    }

    .substrate {
        border-radius: 20px;
        width: max-content;
        padding: 36px;

        .buttonApprove {
            margin-top: 24px;
        } 
    }


    .divider {
        margin: 12px 0; 
        width: 75%;
        height: 1px;
    }

    .wrapBack {
        display: flex;
        align-items: flex-start;
        position: relative;

        .buttonArrow {
            z-index: 10;
            width: max-content;
            height: max-content;
        }

        .title {
            z-index: 5;
            margin-top: 36px;
            width: 100%;
            position: absolute;
            text-align: center;
        }
    }


    .checkContainer {
        margin-top: 36px;
        width: 492px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .description {
            width: 100%;
            max-width: 444px;
            margin-top: 36px;
            background-color: #F6F6F6;
            border-radius: 11px;
            padding: 24px;
            text-align: center;
        }

        .number {
            width: 34px;
            height: 34px;
            background-color: #006FED;
            color: #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }

        .fromAndTo {
            max-width: 444px;
            width: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            margin: 18px 0;

            .option {
                position: relative;
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 16px;
                text-align: left;

                .optionVariable {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 17.6px;
                }
            }
        }

        .data {
            max-width: 444px;
            background-color: #F6F6F6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 16px 24px;
            border-radius: 12px;

            .city {
                width: 60%;

                .cityContent {
                    display: flex;
                    flex-direction: row;
                }
            }

            .cityName {
                font-size: 16px;
                font-weight: 400;
                line-height: 19.49px;
                color: #000000;
           
            }
    
            .gradientEnd {
                display: none;
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%);
            }

            .actionFromAndTo {
                font-size: 13px;
                font-weight: 400;
                line-height: 15.83px;
            }

            .sumValue {
                padding: 0 12px;
            }

            .cityCurrencySum {
                font-size: 16px;
                font-weight: 400;
                line-height: 19.49px;
                color: #000000;
                
            }

            .currency {
                width: 55%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                
                .sum {
                    color: #929292;
                    font-weight: 400;
                    text-align: right;
                    margin-right: 12px;
                }
            }
           
        }
       
    }
  
}

@media (max-width: 1024px) {
    .approveWrapper {
        flex-direction: column;
        align-items: center;


        .substrate {
            margin-bottom: 24px;
        }

        .contentAction {
            width: 492px;
            text-align: center;
        }
      
    }
}

@media (max-width: 768px) {
    .approveWrapper {
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        inset: 0;
        z-index: 9000;
        background-color: #FFFFFF;
        height: 100vh;
        justify-content: flex-start;
        
        .transferTag {
            display: none;
        }

        .substrate {
            background-color: #FFFFFF;
            position: relative;
            width: 100%;
            margin-bottom: 0;
            padding: 24px;
        }

        
        .wrapBack {
            position: relative;
            z-index: 100;

            
            .buttonArrow {
                padding: 8px 0;
                min-width: 0;
            }

            .title {
                margin-top: 0;
            }
        }


        .checkContainer {
            width: 100%;
            margin-top: 0;

            .fromAndTo {
                margin: 24px 0;
            }

            .description {
                padding: 24px;
                margin-top: 24px;
            }


            .data {
                .currency {
                    width: 134px;

                }

                .sumValue {
                    padding: 0 12px 0 0;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: clip;
                    position: relative;
                }

                .city {

                    .cityContent {
                        width: auto;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                        white-space: nowrap; 
                        overflow: hidden;
                        text-overflow: clip;
                        position: relative;
                    }
                }
                
        
                .cityName {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.49px;
                    color: #000000;
              
                }
        
                .gradientEnd {
                    left: 2px;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(246, 246, 246, 0) 60%, rgb(246, 246, 246) 100%);
                }
        
            }
        }


        .infoTitle {
            display: none;
        }
        .infoDesc {
            color: #C2C2C2;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.83px;
        }

        .action {
            padding: 0;
            max-width: 444px;
            text-align: center;
            padding: 0 0 36px;

            .contentAction {
                width: auto;
                text-align: left;
            }
        }

        .divider {
            margin: 36px 0 12px;
            width: 444px;
            height: 1px;
        }

        .hidden {
            display: none !important;
        }
    }
}


@media (max-width: 480px) {
    .approveWrapper {
        .checkContainer {
            .description {
                text-align: left;
            }
            .data {
                .city {

                    .cityContent {
                        width: 164px
                    }

                    .gradientEnd {
                        display: block;
                    }
                }
            }
        }
        .action {
            padding: 0 24px 12px 24px;
            text-align: left;
        }
    }
}

@media (max-width: 400px) {
    .approveWrapper {
        .checkContainer {
            .data {
                padding: 16px 12px;
                .city {
                    .cityContent {
                        width: 134px;
                    }
                }

                .currency {
                    width: 184px;
                }
            }
        }
    }
}