.wrapCards {
    margin-top: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .baseCard {
        width: 444px;
        height: 201px;
        padding: 36px 36px 18px 36px;
        border-radius: 10px;
        text-transform: uppercase;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.23px;
        text-align: left;
    }

    .subTitle {
        font-size: 15px;
        font-weight: 700;
        line-height: 18.27px;
        text-align: left;
    }

    .margins {
        margin: 12px 0 24px 0;
    }

    .textBlack {
        color: #000000;
    }

    .textWhite {
        color: #FFFFFF;
    }

  

    .blueCard {
        background: linear-gradient(110.53deg, #97C2F5 2.4%, #006FED 100%);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: #FFFFFF;


      
        .description {
            width: max-content;
            font-size: 15px;
            font-weight: 700;
            line-height: 18.27px;
            text-align: left;
            color: #BED6F1;
        }
    }

 

    .grayCard {
        background: linear-gradient(110.53deg, #F0F0F0 2.4%, #E3E3E3 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .grayText {
            color: #B8B8B8;
        }

        .cardContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}

@media (max-width: 1024px) {
    .wrapCards {
        flex-direction: column;

        .card {
            width: 494px;
            margin-top: 24px;
        }
    }
}

@media (max-width: 768px) {
    .wrapCards {
       

        .card {
            width: 444px;
            margin-top: 24px;
        }

        // .baseCard {
        //     width: 100%;
        // }
    }
}
@media (max-width: 480px) {
    .wrapCards {

        .baseCard {
            height: auto;
            position: relative;

            .textContent {
                width: 186px;
            }
    
        }

        .card {
            width: 80%;
            margin: 24px
        }

        .hideLogo {
            position: absolute;
            top: 24px;
            right: 24px;
        }

        .textWhite {
            // display: inline-block;
            // width: 186px;
        }

        .margins {
            margin: 16px 0 16px 0;
        }

       
        .blueCard {
            .description {
                width: max-content;
            }
        }
    }
}

@media (max-width: 400px) {
    .wrapCards {
        .baseCard {
            .textContent {
                width: 100%;
            }
        }

        .hideLogo {
            display: none;
        }

        .card {
            margin: 24px 0;
        }
    }
}