.baseCard {
    width: 100%;
    text-transform: uppercase;
    padding: 50px;
    display: flex;
    justify-content: space-between;

    .blueWord {
        color: #006FED;
    }

    .crypto {
        font-size: 13px;
        font-weight: 700;
        line-height: 15.83px;
        text-align: left;
        background-color: #F3F3F3;
        border-radius: 10px;
        width: max-content;
        padding: 2px 8px;
        margin: 24px 0;
    }

    .cryptoDesc {
        color: #808080;
        font-size: 16px;
        font-weight: 400;
        line-height: 17.6px;
        width: 266px;
        text-decoration: none;
    }

    .cryptoDescSmall {
        display: none;
        color: #808080;
        font-size: 16px;
        font-weight: 400;
        line-height: 17.6px;
        width: 266px;
        text-decoration: none;
    }

    .groupeButton {
        width: 400px;
        height: 32px;
        box-shadow: none;
        border: 1px solid #E8E8E8;
        border-radius: 0 10px 10px 0;

        .buttonSelect {
            height: 32px;
            font-size: 15px;
            font-weight: 700;
            line-height: 18.27px;
        }

        .inactive {
            background-color: #FFFFFF;
            color: #000000;
        }
    }
}

.nextContent {
    margin-top: 24px;
    width: 444px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .checkboxRules {
        display: flex;
        align-items: center;
    }

    .buttonNext {
        width: 354px;
        margin-top: 24px;
    }
}


.welcomeCards {
    margin-top: 86px;
}

.transferPanelWrap:hover div .errors {
        background-color: #006FED;
            display: none !important;
    
}

@media (max-width: 1024px) {
    .substrate {
        background-color: #FFFFFF;
    }

    .baseCard {
        flex-direction: column;
        align-items: flex-start;

        .transferSelects {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    
        .cryptoDesc {
            display: none;
        }
        .cryptoDescSmall {
            display: inline;
            margin-top: 24px;
        }
    
    }

}

@media (max-width: 768px) {
    .baseCard {
        padding: 24px;
    }

    .nextContent {
        width: 376px;

        .buttonNext {
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .nextContent {
        width: 100%;
    }

    .baseCard {
        .transferSelects {
            align-items: flex-start;
        }
    }
}
@media (max-width: 400px) {

    .baseCard {
        padding: 24px 12px;
    }
}