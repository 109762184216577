body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  
}


@font-face {
  font-family: 'Proxima Nova Bl';
  src: url('./assets/fonts/proximaNova/ProximaNova-Black.eot');
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
      url('./assets/fonts/proximaNova/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Black.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Black.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url('./assets/fonts/proximaNova/ProximaNova-Extrabld.eot');
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
      url('./assets/fonts/proximaNova/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Extrabld.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Extrabld.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('./assets/fonts/proximaNova/ProximaNova-SemiboldIt.eot');
  src: local('Proxima Nova Semibold Italic'), local('ProximaNova-SemiboldIt'),
      url('./assets/fonts/proximaNova/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-SemiboldIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-SemiboldIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-SemiboldIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url('./assets/fonts/proximaNova/ProximaNovaT-Thin.eot');
  src: local('Proxima Nova Thin'), local('ProximaNovaT-Thin'),
      url('./assets/fonts/proximaNova/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNovaT-Thin.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNovaT-Thin.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('./assets/fonts/proximaNova/ProximaNova-Light.eot');
  src: local('Proxima Nova Light'), local('ProximaNova-Light'),
      url('./assets/fonts/proximaNova/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Light.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Light.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Bl';
  src: url('./assets/fonts/proximaNova/ProximaNova-BlackIt.eot');
  src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
      url('./assets/fonts/proximaNova/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-BlackIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-BlackIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./assets/fonts/proximaNova/ProximaNova-BoldIt.eot');
  src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
      url('./assets/fonts/proximaNova/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-BoldIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-BoldIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-BoldIt.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url('./assets/fonts/proximaNova/ProximaNova-ThinIt.eot');
  src: local('Proxima Nova Thin Italic'), local('ProximaNova-ThinIt'),
      url('./assets/fonts/proximaNova/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-ThinIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-ThinIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-ThinIt.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./assets/fonts/proximaNova/ProximaNova-Bold.eot');
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
      url('./assets/fonts/proximaNova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Bold.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Bold.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./assets/fonts/proximaNova/ProximaNova-RegularIt.eot');
  src: local('Proxima Nova Regular Italic'), local('ProximaNova-RegularIt'),
      url('./assets/fonts/proximaNova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-RegularIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-RegularIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-RegularIt.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url('./assets/fonts/proximaNova/ProximaNova-ExtrabldIt.eot');
  src: local('Proxima Nova Extrabold Italic'), local('ProximaNova-ExtrabldIt'),
      url('./assets/fonts/proximaNova/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-ExtrabldIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-ExtrabldIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-ExtrabldIt.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('./assets/fonts/proximaNova/ProximaNova-Regular.eot');
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
      url('./assets/fonts/proximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Regular.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Regular.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('./assets/fonts/proximaNova/ProximaNova-LightIt.eot');
  src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
      url('./assets/fonts/proximaNova/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-LightIt.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-LightIt.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('./assets/fonts/proximaNova/ProximaNova-Semibold.eot');
  src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
      url('./assets/fonts/proximaNova/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/proximaNova/ProximaNova-Semibold.woff2') format('woff2'),
      url('./assets/fonts/proximaNova/ProximaNova-Semibold.woff') format('woff'),
      url('./assets/fonts/proximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scrollbarCustom::-webkit-scrollbar {
  width: 10px;
  right: 2px;
}
.scrollbarCustom::-webkit-scrollbar-thumb {
  width: 8px;
  height: 30px;
  background-color:#A1A2A3;
  border-radius: 11px;
/* box-shadow: inset 0 0 6px rgb(54, 54, 54);  */
}

.scrollbarCustom::-webkit-scrollbar-track {
  background-color: #F0F0F0;
  /* background: transparent; */
  width: 8px;
  height: 10px;
}