.containerResponse {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16vh;
    
    .wrapBack {
        width: 100%;
        display: none;
        align-items: flex-start;
        position: relative;

        .buttonArrow {
            z-index: 10;
            width: max-content;
            height: max-content;
        }

        .titleOrder {
            z-index: 5;
            margin-top: 36px;
            width: 100%;
            position: absolute;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.49px;

        }
    }

    .substrate {
        border-radius: 20px;
        width: max-content;
        padding:  96px 36px 36px 36px;
        width: 100%;
        margin-right: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .substrateWrapButton {
        max-width: 388px;
        padding: 40px;
        color: #FFFFFF;

        .buttonLinkTg {
            margin-top: 24px;
        }

        .tgIcon {
            font-size: 40px;
            
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 444px;

        .title {
            margin-top: 36px;
            text-align: center;

            .numberOrder {
                display: inline;
            }
        }

        .subtitle {
            margin-top: 24px;
            text-align: center;
            color: #808080;
        }


        .sumValue {
            padding-right: 12px;
        }

        .cityCurrencySum {
            font-size: 16px;
            font-weight: 400;
            line-height: 19.49px;
            color: #000000;
            
        }

        .currency {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            .sum {
                color: #929292;
                font-weight: 400;
                text-align: right;
                margin-right: 12px;
            }

        }
       

        .showValues {
            width: 100%;
            height: 158px;
            border-radius: 12px;
            border: 1px solid #F6F6F6;
            margin-top: 24px;
            box-shadow: 0px 0px 5.94px 0px #87878740;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            .divider {
                width: 100%;
                height: 1px;
                color:#F6F6F6;
            }


            .wrapValues {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px;
                width: 100%;
                height: 100%;

                .city {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 6px;
                }


                .valueFromTo {
                    display: flex;
                    align-items: flex-end;
                }
            }
            .textValues {
                font-size: 19.01px;
                font-weight: 400;
                line-height: 23.15px;
            }
            .disabled {
                color: #929292;
            }
        }
    }


}


@media (max-width: 1024px) {
    .containerResponse {
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        justify-content: flex-start;

        

        .substrate {
            background-color: #FFFFFF;
            padding: 36px;
            margin: 0;
        }

        .substrateWrapButton {
            background-color: #FFFFFF;
            padding: 36px;
        }


        .content {
            .showValues {
                .cityFromTo {
                    // color: #C2C2C2 !important;
                    .currencyFromTo {
                        color: #C2C2C2;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 15.83px;

                    }
                    .currencyPanel {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 15.83px;
                        color: #C2C2C2;
                        border-radius: 5px;
                        padding: 2px 4px;
                        background-color: #F5F5F5;
                    }
                }
            }
        }


      
    }
}

@media (max-width: 768px) {
    .containerResponse {
        height: 100%;

        .substrate {
            padding: 24px;
        }

        .substrateWrapButton {
            padding: 24px;
        }

        .wrapBack {
            display: flex;
            z-index: 100;
            margin-bottom: 36px;

            
            .buttonArrow {
                padding: 1px 0;
                min-width: 0;
            }

            .titleOrder {
                margin-top: 0;
            }
        }
        .content {
            .title {
                .numberOrder {
                    display: none;
                }                    
            }

            .showValues {
                height: 182px;

                .textValues {
                    max-width: 164px;
                    width: auto;
                    display: flex;
                    justify-content: flex-start;
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: clip;
                    position: relative;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19.49px;
                }

            }
        }

        
    }
}

@media (max-width: 480px) {
    .containerResponse {
        .content {
            .showValues {
                .textValues {
                    max-width: 182px;
                }

                .wrapValues {
                    .city {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                    }
                }
            }
        }
    }   
}  
