.wrapPopup {
    background-color: #FFFFFF;

    .disabled {
        cursor: default;
        color: rgba(0, 0, 0, 0.47);
    }
    .unDisabled {
        cursor: pointer;
        color: #000000;
    }

    .showSearch {
        height: 23px;
        font-size: 19px;
        font-weight: 400;
        line-height: 19px;
        text-transform: none;
    }

    .hideSearch {
        width: 24px;
        height: 24px;
    }
    .showSearch:hover {
        background-color: inherit;
    }

    // .popup {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 444px;
    //     height: 160px;
    //     background-color: #FFFFFF;
    //     padding: 24px;
    //     display: flex;
    //     z-index: 400;
    // }

    
    // .currency {
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     font-size: 19.01px;
    //     font-weight: 700;
    //     line-height: 23.15px;
    //     color: #000000;
    //     padding: 0 24px;
    //     background-color: red;
    // }
}




  