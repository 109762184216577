.container {
    width: 100%;
    height: 100%;

    .baseCard {
        width: 100%;
        text-transform: uppercase;
        padding: 50px;
        display: flex;
        justify-content: space-between;

        .blueWord {
            color: #006FED;
        }

        .crypto {
            font-size: 13px;
            font-weight: 700;
            line-height: 15.83px;
            text-align: left;
            background-color: #F3F3F3;
            border-radius: 10px;
            width: max-content;
            padding: 2px 8px;
            margin: 24px 0;
        }

        .cryptoDesc {
            color: #808080;
            font-size: 16px;
            font-weight: 400;
            line-height: 17.6px;
            width: 266px;
        }

        .groupeButton {
            width: 400px;
            height: 32px;
            box-shadow: none;
            border: 1px solid #E8E8E8;
            border-radius: 0 10px 10px 0;

            .buttonSelect {
                height: 32px;
                font-size: 15px;
                font-weight: 700;
                line-height: 18.27px;
            }

            .inactive {
                background-color: #FFFFFF;
                color: #000000;
            }
        }
    }

    .nextContent {
        margin-top: 24px;
        width: 398px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .buttonNext {
            width: 354px;
            margin-top: 24px;
        }
    }
}

@media (max-width: 768px) {
    .container {
        // margin: 82px 0;
    }
}