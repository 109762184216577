.mainListHistory {
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    
    .wrapPopup {
        display: none;
        width: 100%;
    }

    .wrapWebPanel {
        display: block;
        width: 100%;
    }
    
    .buttonList {
        width: fit-content;
        height: 36px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.49px;
        text-align: left;
        background-color: #F5F5F5;
        color:#000000;
        border-radius: 10px;
        margin-right: 14px;
    }
    .buttonList:hover {
        background-color: #cdcdcd;
    }

    .active {
        background-color: #006FED;
        color: #FFFFFF;
    }

    .active:hover {
        background-color: #2288fc;
    }
}

.amount {
    color: #B3B3B3;
}

.blur {
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 25% 0 0 0;
    background-color: #FFFFFF87;
    backdrop-filter: blur(4.5px);
}

@media (max-width: 768px) {
    .mainListHistory {
        width: 100%;

        .wrapPopup {
            display: block;
            position: relative;
        }

        .wrapWebPanel {
            width: 100%;
            display: none;
        }

        .buttonList {
            display: none;
            min-width: max-content;
            width: auto;
        }
    }
}