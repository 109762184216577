.buttonSupport {

    .tgIcon {
        font-size: 40px;
    }
    .tgIcon:nth-of-type(1) {
        font-size: 40px;
    }
}

.buttonSupport:hover {
    background-color: #006FED !important;
}

@media (max-width: 768px) {
    .buttonSupport {

        .tgIcon {
            font-size: 28px;
        }
        .tgIcon:nth-of-type(1) {
            font-size: 28px;
        }
    }
}