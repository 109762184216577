.workWrap {
    margin-top: 36px;

    .card {
        padding: 48px 36px;
    }

    .contentCard {
        text-align: center;



        .moneyPath {
            margin-top: 60px;
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: center;


            .arrowLine {
                width: 252px;
                height: 138px;
                display: flex;
                align-items: center;
                flex-direction: column;
                background-image: url('../../../../../assets/icon/horizontalArrowBg.png');
                background-repeat: no-repeat;

                .descriptionStep {
                    display: inline-block;
                    width: 164px;
                    margin-top: 38px;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 14.3px;
                    text-align: center;
                    color: #808080;
                }

                .fromToCard {
                    background-color: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    width: 130px;
                    height: 28px;
                    margin-top: 16px;

                    .fromToText {
                        color: #FFFFFF;
                        padding: 0px 6px;
                    }
                }
            }


            .styledName {
                background-color: #006FED;
            }
        }
    }
}

@media (max-width: 1024px) {
    .workWrap {

        .card {
            padding: 36px;
            background-color: transparent;
        }

        .contentCard {
            text-align: left;


            .moneyPath {
                margin-top: 60px;
                flex-direction: column;

                .arrowLine {
                    height: 176px;
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: center;
                    background-image: url('../../../../../assets/icon/verticalArrowBgLeft.png'), 
                    url('../../../../../assets/icon/verticalArrowRightBg.png');
                    background-position: left, right;

                    .descriptionStep {
                        margin-top: 14px;
                        text-align: left;
                        // margin-left: 24px;
                    }

                    .fromToCard {
                        margin-top: 0;
                        margin-right: 32px;
                    }
                }
            }
        }

    }
}